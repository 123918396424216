<template>
  <f7-page class="orderview-page">
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_ORDER_REVIEW')" :type="navType" :search="false" :cart="false" />
    </template>

    <div class="orderview-container">
      <div class="container">
        <div class="title">
          <h1>{{ $t.getTranslation("LBL_ORDER_REVIEW") }}</h1>
        </div>

        <div v-if="Product" class="product-container">
          <div class="orderproductitem">
            <div class="image">
              <div class="prod-image-disp" :style="{ width: '100px', height: '100px', 'background-image': 'url(' + helpers.getImage(Product.Product.PrimaryImage, 'PRODUCT') + ')' }"></div>
            </div>
            <div class="content" style="width: calc(100% - 100px)">
              <h3>{{ helpers.getAttribute(Product.Product, $t.getLanguage(), "Name") }}</h3>
              <div v-if="Product?.PackingQty?.Total"><font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x {{ Product.PackingQty.Total }}</div>

              <h4>{{ $t.getTranslation("LBL_QUANTITY") }}: {{ Product.QtyOrdered }}</h4>
              <h4>{{ $t.getTranslation("LBL_TOTAL") }}: {{ helpers.formatPrice(Product.UnitPrice, Order.CurrencyCode) }}</h4>
            </div>
          </div>
        </div>

        <div style="display: flex; align-items: center; justify-content: center">
          <star-rating :star-size="40" :show-rating="false" :increment="0.5" :read-only="false" @update:rating="setRating"></star-rating>
        </div>

        <f7-list no-hairlines style="width: 100%">
          <f7-list-input v-model:value="formData.Content" name="Content" :label="$t.getTranslation('LBL_WRITE_REVIEW')" :placeholder="$t.getTranslation('LBL_TYPE_HERE')" floating-label outline type="textarea" validate clear-button>
          </f7-list-input>
          <f7-list-item checkbox :checked="formData.IsPrivate === 1" :value="1" :title="$t.getTranslation('LBL_POST_ANONYMOUSLY')" @change="onPrivacyChange"> </f7-list-item>
        </f7-list>

        <f7-button fill style="margin-top: 10px" large @click="submitRating">{{ $t.getTranslation("BTN_SUBMIT_REVIEW") }}</f7-button>
      </div>
    </div>
  </f7-page>
</template>

<script>
import _ from "lodash";
import { configs } from "@/utils/configs";
import { defineComponent, ref, onMounted, inject, reactive, defineAsyncComponent } from "vue";
import { post, get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";

// import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";
// import StarRating from "@/components/rating/star-rating.vue";

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));
const StarRating = defineAsyncComponent(() => import(/* webpackChunkName: "star-rating" */ /* webpackMode: "lazy" */ "@/components/rating/star-rating.vue"));

export default defineComponent({
  name: "OrderReviewPage",
  components: {
    StarRating,
    ProductNavigationComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    let Order = ref({});
    let Product = ref(null);

    const store = useStore();
   
    const formData = reactive({
      Rating: 0,
      Content: null,
      IsPrivate: 0,
    });

    let OrderKey = ref(props.f7route?.params?.order);
    let ProductKey = ref(props.f7route?.params?.product);

    let navType = "back";

    const setRating = (rating) => {
      formData.Rating = rating;
    };

    const submitRating = async () => {
      let ret = await post("/mobile/product/review", {
        ProductKey: ProductKey.value,
        Rating: formData.Rating,
        Content: formData.Content,
        IsPrivate: formData.IsPrivate,
        OrderKey: OrderKey.value,
      });
      if (!ret) return;
      props.f7router.back({ force: true });
    };

    onMounted(async () => {
      if (!props.f7route?.params?.order) return;
      let ret = await get("/order/view", { key: props.f7route.params.order });
      if (!ret) return props.f7router.back({ force: true });

      let prod = _.find(ret.Order.Items, (r) => {
        return r.ProductKey == r.Product.ProductKey;
      });

      Product.value = prod;
      Order.value = ret.Order;
    });

    const onPrivacyChange = (event) => {
      formData.IsPrivate = event && event.target && event.target.checked ? 1 : 0;
    };

    return {
      submitRating,
      setRating,
      navType,
      formData,
      Product,
      Order,
      helpers,
      onPrivacyChange,
    };
  },
});
</script>
